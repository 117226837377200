.inner_boxes img,
.categories_inner_boxes img {
  width: 100%;
  border-radius: 9px;
  cursor: pointer;
}
.categories_inner_boxes img {
  max-height: 300px;
  min-height: 300px;
  object-fit: cover;
}
.video_src img {
  height: 250px;
  border-radius: 9px;
  cursor: pointer;
  object-fit: fill;
}
.category_boxes {
  margin-top: 30px;
}
.inner_boxes,
.categories_inner_boxes {
  margin-bottom: 30px;
  position: relative;
}
.inner_boxes p,
.categories_inner_boxes p {
  margin: 0;
  position: absolute;
  bottom: 15px;
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}
.category_boxes {
  padding-bottom: 50px;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

:root {
  --color-black: #000000;
  --color-primary: #aeca73;
  --color-white: #fff;
  --color-grey: #14161a;
  --color-lightgrey: #616161;
  --color-softgrey: #dedee3;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
  color: var(--color-black);
}

.seprator-green {
  height: 20px;
  width: 100%;
  background: var(--color-primary);
}

.main-login-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 100%;
}

.btn-long {
  background: #aeca73;
  width: 100%;
  padding: 15px;
  border: 0;
  color: #fff;
  font-size: 24px;
}

.title_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}
.title_flex h1 {
  color: #333333;
  font-weight: 600;
  margin: 0;
}
.title_flex h1 a {
  color: #333333;
}
.title_flex h1 i {
  margin-right: 10px;
}
.title_btns {
  display: flex;
  align-items: center;
}
.btn_primary,
.btn_primary:hover {
  background: #aeca73;
  text-decoration: none;
  color: #fff;
  padding: 7px 32px;
  border-radius: 10px;
  font-weight: 500;
  margin-right: 20px;
}
.gray_btn img {
  width: 17px;
  margin-top: 10px;
}
.gray_btn {
  background: #77777733;
  border-radius: 100px;
  text-align: center;
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 12px;
  cursor: pointer;
}
.inner_boxes img {
  width: 100%;
  border-radius: 9px;
  cursor: pointer;
}
.category_boxes {
  margin-top: 30px;
}
.inner_boxes {
  margin-bottom: 50px;
  position: relative;
  cursor: pointer;
}
.inner_boxes p {
  margin: 0;
  position: absolute;
  bottom: 15px;
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
}
.category_boxes {
  padding-bottom: 50px;
}
.video_timing {
  background: #40474dcc;
  color: #fff;
  font-size: 13px;
  padding: 3px 7px;
  border-radius: 5px;
  position: absolute;
  bottom: 4px;
  right: 5px;
  font-weight: 600;
}
.video_src {
  position: relative;
}
.video_desc {
  position: relative;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.video_thumbnail img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0;
  object-fit: fill;
}
.thumb_desc h3 {
  color: #40474d;
  font-size: 20px;
  margin: 0;
}
.thumb_desc p img {
  width: auto;
  filter: brightness(0) saturate(100%) invert(68%) sepia(6%) saturate(331%)
    hue-rotate(166deg) brightness(97%) contrast(95%);
}
.thumb_desc p {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  color: rgb(64 71 77 / 59%);
  font-size: 14px;
  margin-top: 5px;
}

.thumb_desc p.streaming span {
  width: 6px;
  height: 6px;
  display: inline-block;
  background: #acb2b9;
  border-radius: 50%;
  margin: 0 10px;
}

@media (max-width: 1024px) {
  .btn-long {
    padding: 10px;
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .btn-long {
    font-size: 16px;
  }
}

.queue_videos .inner_boxes {
  background: #f5f5f5;
  padding: 15px;
  border-radius: 5px;
}

.inner_boxes a {
  text-decoration: none;
}

@media screen and (min-width: 992px) {
  .video_main_wrapper {
    max-width: 1161px;
    margin: 0 auto;
    background-color: #f5f5f5;
    border-radius: 20px;
  }
}

.video_main_wrapper > .third-party-player div {
  width: 100% !important;
  height: 65vh !important;
}

.modal-content {
  border-radius: 0;
}

.videoPlayer-modal {
  border-radius: 10px;
  width: 100% !important;
}

.modal-header .btn-close:focus {
  box-shadow: none;
}

.time_range,
.volume_range {
  cursor: pointer;
}

.modal-header .btn-close {
  opacity: 1;
}

.modal-header {
  border-bottom: 1px solid #ececec;
}

.modal-header h5 {
  color: #40474d;
}

.video_section {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
}

.left_img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.video_cover {
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  background: black;
  color: white;
}

.video_player_container {
  width: 100%;
  height: 100%;
  min-width: 1042px;
  justify-content: center;
  align-items: center;
  object-fit: fill !important;
}

.video_cover .play_icon_top {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150px;
}

.video_cover img {
  width: 100%;
  border-radius: 0;
}

.video_bottom {
  /* min-height: 144px; */
  background-color: #000;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: flex-end;
  padding: 15px;
  padding-bottom: 30px;
  margin-top: -7px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.video_bottom img {
  width: auto;
}

.video_bottom .video_bottom_left {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.video_bottom .button_content {
  color: #ffff;
}

.video_bottom .button_content .button_element {
  display: flex;
  margin-right: 20px;
  align-items: center;
}

.video_bottom .video_name {
  display: flex;
  align-items: center;
}

.video_bottom .video_bottom_left .left_content {
  margin-left: 10px;
}

.video_bottom .video_bottom_left .left_content h5 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.05em;
  color: #ffffff;
  margin-bottom: 0;
}

.video_bottom .video_bottom_left .left_content h6 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.05em;
  color: #b3b3b3;
  margin-bottom: 0;
}

.video_bottom .video_bottom_center {
  display: flex;
  flex-direction: column;
}

.video_bottom .center_top {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video_bottom .center_bottom {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.video_bottom .video_bottom_center .center_bottom .center_start_time h5,
.video_bottom .video_bottom_center .center_bottom .center_end_time h5 {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #c4c4c4;
  margin-bottom: 0;
  margin-top: 4px;
}
.center_start_time h5,
.center_end_time h5 {
  margin: 0 !important;
}

.video_bottom .video_bottom_center .center_bottom .center_range_slider {
  flex: auto;
}

.video_bottom .video_bottom_center .center_top .shuffle_n {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  background: #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.video_bottom .video_bottom_center .center_top .shuffle_n img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(95%) saturate(20%)
    hue-rotate(39deg) brightness(101%) contrast(107%);
}

.video_bottom .video_bottom_center .center_top .reverse {
  margin-left: 30px;
}

.video_bottom .video_bottom_center .center_top .center_padding {
  margin: 0 10px;
}

.center_padding {
  width: 40px;
  height: 40px;
}

.video_bottom .video_bottom_right {
  display: flex;
  align-items: center;
  justify-content: center;
}

#progressbar input[type="range"],
#progressbar2 input[type="range"] {
  /* -webkit-appearance: none; */
  /* -moz-appearance: none; */
  border-radius: 26px;
  height: 5px;
  width: 90%;
  background-color: #c4c4c4;
  border-radius: 2.5px;
  margin: 0 11px;
  color: #aeca73 !important;
}

#progressbar2 input[type="range"] {
  width: 95%;
}

#progressbar input[type="range"]::-webkit-slider-thumb,
#progressbar2 input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
}

#progressbar:hover input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid blue;
  border-radius: 50%;
  background: #fff;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.6);
}

.video_bottom .video_bottom_right .right_volume_plus .volume-mobile {
  display: none;
}

/* New CSS */

.video_src .img {
  min-height: 230px;
  max-height: 230px;
}

.video_bottom .video_bottom_left .left_content p {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  font-size: 14px;
  margin-top: 5px;
}

.left_content p.streaming span {
  width: 6px;
  height: 6px;
  display: inline-block;
  background: #acb2b9;
  border-radius: 50%;
  margin: 0 10px;
}

.shuffle_remove {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shuffle_add {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
}

.shuffle {
  filter: brightness(0) saturate(100%) invert(0%) sepia(95%) saturate(20%)
    hue-rotate(39deg) brightness(101%) contrast(107%);
}

.you_tube_video_name {
  display: none;
  align-items: center;
  padding: 15px 10px;
  justify-content: space-between;
}

.you_tube_left_img {
  display: flex;
  align-items: center;
}

.you_tube_left_content {
  margin-left: 10px;
}

.you_tube_left_img img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.you_tube_left_content h5 {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.05em;
  margin-bottom: 0;
}

.you_tube_left_content p {
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  font-size: 14px;
  margin-top: 5px;
  color: #242424;
}

.you_tube_streaming span {
  width: 6px;
  height: 6px;
  display: inline-block;
  background: #2b2828d4;
  border-radius: 50%;
  margin: 0 10px;
}

.button_element .share_button {
  background-color: transparent;
  margin-right: 15px;
  color: #ffffff;
  border: 0;
}

.button_element .share_button img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.video_tag_button_wrapper {
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 2;
  bottom: 4px;
  height: fit-content;
  display: flex;
}

.video_tag_button {
  /* padding: 0 5px;
  color: #b000ff;
  z-index: 2;
  background: rgb(0 0 0 / 88%);
  border-radius: 5px;
  color: rgb(174 202 115);
  height: fit-content;
  font-size: 15px;
  margin-left: 5px; */
  background: rgb(51 51 51);
  border-radius: 5px;
  color: #aeca73;
  font-size: 15px;
  margin-left: 5px;
  padding: 5px 10px;
  font-weight: 500;
}

.video_tag_button_player {
  background: rgb(51 51 51);
  border-radius: 5px;
  color: #aeca73;
  font-size: 17px;
  margin-left: 5px;
  padding: 5px 10px;
  font-weight: 500;
}

.video_src::after {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 40px;
  background-image: linear-gradient(#000000bd, #ffff0000);
  z-index: 1;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.save_button {
  display: flex;
  align-items: center;
  border: transparent;
}

.tbg {
  background-color: transparent;
  color: #fff;
  margin-left: 10px;
}

.save_button button {
  background: transparent;
  border: transparent;
  margin-right: 15px;
  color: #878181;
  border: 0;
}

.save_button img {
  width: 20px;
  height: 15px;
  margin-right: 5px;
  border-radius: 0px;
  cursor: auto;
}

.button_element a {
  color: #ffffff;
}

.button_element a img {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.social_share_icons {
  box-shadow: 4px 2px 10px 1px rgb(0 0 0 / 20%);
  position: absolute;
  right: 165px;
  bottom: -90px;
  background-color: #000;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}

.social_share_icons button svg {
  margin-right: 3px;
}

.social_share_icons button {
  margin-bottom: 5px;
  color: #fff !important;
}

.share_btn_text {
  display: none;
  position: absolute;
  background-color: #000;
  padding: 0 10px;
  border-radius: 3px;
}

.social_share_icons button:hover span {
  display: block;
  transform: translateX(27px);
  transition: 0.3s all ease-in-out;
}

/* New Css End */

@media screen and (max-width: 1200px) {
  .video_cover .play_icon_top {
    width: 100px;
  }

  .video_bottom .video_bottom_right {
    /* justify-content: flex-end; */
  }

  #progressbar input[type="range"] {
    width: 110px;
  }
}

@media screen and (max-width: 991px) {
  .video_bottom .video_bottom_center {
    /* width: 100%; */
    display: flex;
    flex-direction: column;
  }

  .video_bottom .video_bottom_center .center_bottom {
    /* margin-top: 0px; */
  }

  .video_bottom {
    flex-wrap: wrap;
  }

  .video_cover .play_icon_top {
    width: 70px;
  }

  .video_bottom .video_bottom_left {
    width: auto;
    display: flex;
    align-items: center;
    flex-basis: 100%;
    justify-content: space-between;
    margin-top: 30px;
    order: 2;
  }

  .video_bottom .video_bottom_right {
    width: auto;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    flex-basis: 50%;
    order: 3;
  }

  .social_share_icons {
    box-shadow: 4px 2px 10px 1px rgb(0 0 0 / 20%);
    position: absolute;
    right: 100px;
    bottom: 5px;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 5px 10px;
    border-radius: 50px;
  }
}

.video_thumb_image {
  width: 120px;
  height: 70px;
  border-radius: 5px;
  object-fit: fit;
  margin-left: 5px;
}

.video_bottom_center {
  width: 100% !important;
}

.video_bottom_right {
  display: flex;
}

.video_bottom_right img {
  width: 20px;
  height: 20px;
}

.center_range_slider {
  width: 100%;
}

#progressbar {
  width: 100% !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#progressbar input {
  width: 100%;
}

.center_bottom {
  width: 100%;
}

.center_range_slider {
  display: flex;
  width: 100%;
}

@media screen and (max-width: 767px) {
  #progressbar input[type="range"] {
    width: 120px;
  }

  .video_cover .play_icon_top {
    width: 50px;
  }
}

@media screen and (max-width: 576px) {
  .video_bottom .video_bottom_left {
    flex-basis: 100%;
    flex-wrap: wrap;
  }

  .video_bottom .video_bottom_left ~ div {
    margin-top: 10px;
  }

  .video_bottom .video_bottom_right {
    flex-basis: 10%;
  }

  .video_bottom .video_bottom_right {
    margin-top: 5px;
  }

  .video_bottom .video_bottom_right {
    justify-content: flex-end;
  }

  .video_bottom .video_bottom_right .right_volume_reduce,
  .video_bottom .video_bottom_right .right_volume_range {
    /* display: none; */
  }
}

@media screen and (max-width: 500px) {
  #progressbar input[type="range"] {
    width: 120px;
  }

  .video_cover .play_icon_top {
    width: 50px;
  }

  #progressbar2 input[type="range"] {
    width: 93%;
  }

  .social_share_icons {
    box-shadow: 4px 2px 10px 1px rgb(0 0 0 / 20%);
    position: absolute;
    right: 230px;
    bottom: 5px;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 2px 8px;
    border-radius: 50px;
  }
}

@media screen and (max-width: 440px) {
}

@media screen and (max-width: 400px) {
  #progressbar2 input[type="range"] {
    width: 90%;
  }
}

.close_button {
  position: absolute;
  right: 50px;
  top: 0;
  background: #fff;
  z-index: 2;
  border-radius: 50px;
}

.close_button img {
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.disable_icon {
  cursor: not-allowed !important;
}

@media (max-width: 1200px) {
  #progressbar input[type="range"] {
    width: 100% !important;
  }
}
@media (max-width: 1199px) {
  .video_player_container {
    min-width: auto;
  }
}
@media (max-width: 780px) {
  .video_bottom .center_top {
    left: 0;
    transform: none;
    top: -8px;
  }
  .video_bottom .video_bottom_left {
    justify-content: center;
  }
}
@media (max-width: 576px) {
  .close_button {
    right: 14px;
    top: 9px;
  }
  #progressbar input[type="range"] {
    width: auto !important;
  }
}
@media (max-width: 490px) {
  .video_bottom > div {
    display: block !important;
  }
  .video_bottom .center_top {
    position: relative;
  }
  .close_button {
    right: 0;
    top: 0;
  }
}
