.container_wrapper {
}

.main_wrapper {
  position: relative;
  min-height: 100vh;
}

.main_header {
  background-color: #2d3335;
}

@media (min-width: 1024px) {
  .main_container_wrapper {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}
