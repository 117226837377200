.profile_canvas {
}

.sidebarMenuInner {
  height: calc(100vh - 120px);
  list-style: none;
  color: #000;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 22px;
  font-size: 16px;
  padding-bottom: 30px;
  padding-top: 0;
  padding: 0;
  cursor: pointer;
}

.sidebarMenuInner h3 {
  font-family: "Roboto Condensed", sans-serif !important;
  font-weight: 600;
  line-height: 22px;
  margin: 8px 0;
  font-size: 22px;
}

.sidebarMenuInner li a {
  font-size: 15px;
}

.header_user_email {
  font-family: "Roboto Condensed", sans-serif !important;
  text-transform: none;
  font-size: 16px;
}

.user {
  padding: 20px 0;
  text-align: center;
  background: linear-gradient(180deg, #fafafa, #f5f5f5);
}

.user img {
  border-radius: 100%;
  margin-bottom: 8px;
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.close_sidebar {
  cursor: pointer;
  position: absolute;
  right: 30px;
}

.close_sidebar img {
  height: 20px;
  width: 20px;
}

.account_btn {
  background-color: #33bfd7 !important;
  color: #fff;
  border-radius: 20px;
  margin-right: 8px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  font-size: 16px;
  font-family: "Roboto Condensed", sans-serif !important;
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  text-align: center;
  display: inline-block;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;
  width: 100%;
  max-width: 150px;
}

.logout_btn {
  border: 1px solid #33bfd7 !important;
  border-radius: 20px !important;
  font-family: Roboto Condensed, sans-serif !important;
  font-weight: 700 !important;
  max-width: 150px;
  width: 100%;
}

.account_btn:hover {
  color: white;
}

.heading_text {
  font-family: "Roboto Condensed", sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  margin-top: 25px;
  padding: 0 25px;
}

.application_submenu {
  padding: 0;
}

.application_submenu a {
  font-family: "Roboto Condensed", sans-serif !important;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  color: #000 !important;
}
.application_submenu li {
  padding: 10px 25px;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  text-transform: uppercase;
  width: 100%;
  display: block;
}

.application_submenu li.active {
  background-color: #33bfd7;
  color: #fff;
}

.application_submenu img {
  max-width: 22px;
  margin-right: 15px;
}

.application_submenu li:hover {
  background-color: #33bfd7;
}

.application_submenu a li:hover {
  color: #fff !important;
}

.admin_btn {
  bottom: 20px;
  padding: 20px;
  position: static;
  width: 100%;
}

.admin_btn a {
  font-family: Roboto Condensed, sans-serif !important;
  width: 100%;
}

.btn_edit_profile {
  background: linear-gradient(180deg, #69cdde, #01a7ca);
  border: 1px solid #69cdde;
  border-radius: 10px;
  color: #fff !important;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  min-width: 85px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

@media (max-height: 700px) {
  .sidebarMenuInner {
    max-height: 600px !important;
    height: 600px !important;
  }
}
