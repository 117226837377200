.gray_btn img {
  width: 17px;
  margin-top: 10px;
}

.gray_btn {
  /* background: #77777733; */
  border-radius: 100px;
  text-align: center;
  /* width: 40px; */
  height: 40px;
  display: inline-block;
  margin-right: 12px;
  /* margin-left: 12px; */
  cursor: pointer;
}

.btn_primary,
.btn_primary:hover {
  background: #aeca73;
  text-decoration: none;
  color: #fff;
  padding: 7px 32px;
  border-radius: 10px;
  font-weight: 500;
  margin-right: 20px;
  outline: none;
  border: 0;
}

.title_flex h1 {
  color: #333333;
  font-weight: 600;
  margin: 0;
  width: 100%;
}

.title_btns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.title_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
}
.top_back_image {
  display: flex;
  color: #333333;
  align-items: center;
  text-decoration: none;
  background: transparent;
  border-radius: 10px;
  border: 0;
}
.top_back_image span {
  color: #333333;
}
.top_back_image img {
  width: 20px;
  height: 20px;
}
.top_back_image span {
  margin-left: 10px;
  text-transform: capitalize;
}
.user_image {
  border-radius: 50px;
  height: 30px;
  width: 30px;
  object-fit: cover;
}

.header_top_section {
  display: flex;
  align-items: center;
}

.search_bar {
  margin-right: 10px;
  margin-left: 10px;
  padding: 0;
  position: relative;
}

.search_bar > div {
  margin-bottom: 0px !important;
}

.round_dropdown {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.user_logo {
  /* width: 100%; */
  height: 32px;
  /* max-width: 49px; */
}

.user_header_name {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  text-transform: capitalize;
  text-transform: uppercase;
  cursor: pointer;
}
