.full-screen {
  height: calc(100vh - 95px);
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #aeca73;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/************************Modal CSS*****************************/
.modal-content {
  border-radius: 0;
}

.modal-header .btn-close:focus {
  box-shadow: none;
}

.modal-header .btn-close {
  opacity: 1;
}

.modal-header {
  border-bottom: 1px solid #ececec;
}

.modal-header h5 {
  color: #40474d;
}

.modal-body {
  max-height: 520px;
  overflow: auto;
}

.video-modal {
  position: relative;
}

.video-modal-post .modal-content {
  background: transparent;
}

.video-modal.modal-body {
  max-height: 100%;
}

.common .modal-body {
  text-align: center;
  overflow: hidden;
}

.common .modal-body a {
  display: inline-block;
  min-width: 160px;
}

.common .modal-body {
  padding: 0rem 50px 1rem;
  padding-bottom: 30px;
}

.common .modal-header {
  border: none;
  padding-bottom: 0;
}

.common .modal-content {
  border-radius: 10px;
}

.common .modal-header .btn-close {
  background-color: #e5e5e5;
  border-radius: 100px;
  background-size: 12px;
}

.common .listing p {
  font-size: 26px;
  line-height: 30px;
  margin: 0 auto 20px;
}

.red_btn,
.red_btn:hover {
  background: #d72525;
  color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 600;
  margin-left: 10px;
  border: transparent;
}

.green_btn,
.green_btn:hover {
  background: #aeca73;
  color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 600;
  margin-left: 10px;
  border: transparent;
  /* width: 21%; */
}

.accordion-item:last-of-type {
  margin-bottom: 0;
}

.input_field input {
  border: 2px solid #828282;
  border-radius: 3px;
  height: 40px;
  width: 100%;
  margin-right: 15px;
  padding: 10px;
}

input:focus {
  outline: none;
}

.input_field {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.input_field .btn.btn-primary {
  text-decoration: none;
  padding: 7px 30px;
  height: 40px;
  background: #aeca73;
  border-color: #aeca73;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
}

.input_field .input-div {
  display: block;
  width: 100%;
  margin-right: 20px;
}

.listing ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.listing {
  margin-top: 20px;
}

.listing ul li img {
  width: 28px;
  /* margin-right: 10px; */
}

.listing ul li > p {
  display: flex;
  align-items: center;
  margin: 0;
  color: #40474d;
}

.listing ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.title_flex a {
  text-decoration: none;
}

.grey_btn_icon {
  display: contents;
}

.grey_btn,
.grey_btn:hover {
  background: #f5f5f5;
  color: #999;
  padding: 10px 20px;
  border-radius: 6px;
  text-decoration: none;
  font-weight: 600;
  margin-left: 10px;
  border-color: transparent;
}

.accordion .form-group {
  display: block;
  margin-bottom: 0;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.accordion .form-group label {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
}

.accordion-button {
  border: none;
  padding: 0;
  box-shadow: none !important;
}

.accordion .form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: #eeedef;
  border: none;
  box-shadow: 0 1px 2px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%);
  padding: 12px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.accordion .form-group input:checked + label:before {
  background-color: #aeca73;
}

.accordion .form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.accordion-header {
  display: flex;
  align-items: center;
  padding: 10px;
}

.accordion-item:not(:first-of-type) {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item {
  margin-bottom: 10px;
}

.accordion-button:not(.collapsed) {
  color: #212529;
  background-color: transparent;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-body .accordion .form-group label:before {
  padding: 10px;
}

.accordion-body .accordion .form-group input:checked + label:after {
  top: 3px;
  left: 7px;
  width: 5px;
  height: 12px;
}

.accordion-body .accordion .accordion-header {
  border-bottom: 1px solid #eeedef;
}

.accordion-body .accordion .accordion-body {
  padding: 15px 10px;
}

.accordion-body .accordion {
  background: #eeedef;
  padding: 10px;
  border-radius: 8px;
}

.accordion-body {
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
  padding: 5px 10px 10px 10px;
}
.collapsing {
  transition: height 0.5s ease;
}

.accordion-collapse.collapse.show {
  transition: all 0.1s ease-in-out;
  -webkit-transition: all 0.1s ease-in-out;
}

.accordion-body .accordion-item:last-child {
  margin-bottom: 0;
}

.accordion-body .accordion .accordion-body .form-group label:before {
  margin-right: 15px;
}

.accordion-body .accordion .accordion-body .form-group {
  /* margin-bottom: 20px; */
  /* width: 48%; */
  display: inline-block;
}

.accordion-body
  .accordion
  .accordion-body
  .form-group
  input:checked
  + label:after {
  top: 5px;
}

.box {
  width: 25px;
  height: 25px;
  display: block;
  margin-right: 8px;
}

.grey_box {
  background: #7b7d80;
}

.orange_box {
  background: #ed8b4a;
}

.blue_box {
  background: #86cfdb;
}

.red_box {
  background: #db5254;
}

.green_box {
  background: #c8d970;
}

@media screen and (max-width: 576px) {
  .common .modal-body {
    padding: 1rem 20px;
  }
  .common .modal-body a {
    min-width: 120px;
  }
  #category .modal-header {
    display: block;
  }
}
.seprator-green {
  height: 20px;
  width: 100%;
  background: #aeca73;
}
.modal-dialog.video-popup .modal-content {
  border-radius: 10px;
  border: none !important;
}
.invitation-warning-modal .category-modal-header {
  border-bottom: 0px;
}
.no-data-row {
  width: 100%;
  margin: auto;
  text-align: center;
  display: block;
}
.no-data-row img {
  width: 520px;
}

.category-modal-header.modal-header {
  display: block;
}

.btn.header-item:first-child:active {
  border: 0px;
}
button.btn.header-item:focus:not(:focus-visible) {
  border: 0px;
  outline: 0;
}
.dropdown-item.external-event:hover {
  cursor: pointer;
}

.category-video-invitation .form-group label:before {
  top: -7px;
  left: -5px;
  position: absolute;
  box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
}

.category-video-invitation .form-group input:checked + label:after {
  top: -4px;
  left: 4px;
}

.infinite-scroll-component {
  overflow-x: hidden !important;
}

.invite-success-image {
  width: 100px;
  height: 100px;
}

/********* Loader CSS **********/
.card-content .ph-row {
  display: flex;
  align-items: center;
}
.ph-picture {
  height: 205px;
  background: #ebebebeb;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  animation: wave-lines 2s infinite ease-out;
}
.big.empty {
  padding: 20px 5px;
}
.loader-img {
  height: 30px;
}
.card-content .main-content {
  width: 100%;
  margin-right: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.card-content .big-title {
  height: 20px;
  background: #ebebeebe;
  width: 100%;
  border-radius: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  animation: wave-lines 2s infinite ease-out;
}
.card-content .big-description {
  height: 10px;
  background: #ebebeebe;
  width: 100%;
  margin-top: 5px;
  border-radius: 2px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, rgba(130, 130, 130, 0.2)),
    color-stop(18%, rgba(130, 130, 130, 0.3)),
    color-stop(33%, rgba(130, 130, 130, 0.2))
  );
  background: linear-gradient(
    to right,
    rgba(130, 130, 130, 0.2) 8%,
    rgba(130, 130, 130, 0.3) 18%,
    rgba(130, 130, 130, 0.2) 33%
  );
  animation: wave-lines 2s infinite ease-out;
}

@keyframes wave-lines {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@media (max-width: 991px) {
  .modal-dialog {
    max-width: 90%;
  }
}

@media (max-width: 576px) {
  .modal-dialog {
    max-width: 100%;
  }
}
