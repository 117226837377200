.footer_content {
  padding: 15px 0 6px;
  background-color: #222;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.footer_info {
  font-size: 12px;
  padding-bottom: 10px;
  color: #888;
  text-align: center;
}

.footer_info a {
  color: #888;
  text-decoration: none;
  font-weight: 700;
}
